<template>
    <main>
         <div class="row no-gutters">
            <div class="form-group col-lg-4 col-sm-4 pl-2 mt-4" v-for="item in $v.complicaciones_identificadas.$each.$iter" :key="item.id">
                <label  class="mb-0"> {{item.nombre.$model}} </label>
                <textarea class="form-control" v-model="item.detalle.$model" :class="{'is-invalid':item.detalle.$error}"></textarea>
            </div>
        </div>
         <button class="btn btn-success" @click="guardar()">Guardar</button>
    </main>
</template>
<script>
import {required} from "vuelidate/lib/validators";
import complicacionesIdentificadasService from "../../../../services/complicacionesIdentificadasService";
import historiaComplicacionesIdentificadasService from "../../../../services/historiaComplicacionesIdentificadasService";
import {isEmpty} from 'lodash';
import Toast from '../../../../components/common/utilities/toast';
export default {
    props:['idHistoria','finalizada'],
    data(){
        return {
            control:false,
            complicaciones_identificadas:[],
        }
    },
    validations (){
        return {
            complicaciones_identificadas:{
                $each:{
                    nombre:{required},
                    detalle:{required}
                }
            }
        }
    },
    methods:{
        validate(){
            this.$v.$touch();
        },
        async guardar(){
            try{
                this.$v.$touch();

                if(this.$v.$invalid) return false;

                let revision ={
                    id_historia:this.idHistoria,
                    partes:this.complicaciones_identificadas,
                }

                this.LoaderSpinnerShow();
                if(this.control){
                    await historiaComplicacionesIdentificadasService.update(revision);
                    this.control=true;
                }else{
                    await historiaComplicacionesIdentificadasService.store(revision);
                    this.control=true;
                }
                
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });

            } catch (error) {
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            } 
            
        },
        async cargarComplicacionesIdentificadas(){
            const response = await complicacionesIdentificadasService.index();
            this.complicaciones_identificadas= response.data.map(x=>{
                return {
                    ...x,
                    detalle:'NORMAL'
                }
            });
        },
    },
    watch:{
        finalizada: async function (value){
            if(value==='N'){
                 const response = await historiaComplicacionesIdentificadasService.showByHc(this.idHistoria);
                if(!isEmpty(response.data)){
                     this.control=true;
                     response.data.forEach(x=>{
                        let i = this.complicaciones_identificadas.findIndex(p => parseInt(p.id) === parseInt(x.id_comp));
                        if(i!==-1)  this.complicaciones_identificadas[i].detalle = x.detalle;
                    });
                    
                }
            } 
        }
    },
    async created(){
       await this.cargarComplicacionesIdentificadas();
        if(this.finalizada==='N'){
            const response = await historiaComplicacionesIdentificadasService.showByHc(this.idHistoria);
            if(!isEmpty(response.data)){
                    this.control=true;
                    response.data.forEach(x=>{
                    let i = this.complicaciones_identificadas.findIndex(p => parseInt(p.id) === parseInt(x.id_comp));
                    if(i!==-1)  this.complicaciones_identificadas[i].detalle = x.detalle;
                });
            }
        }
    }
}
</script>